<template>
  <div>
    <general-table
      :api-url="APIURL"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :selectable="false"
    >
      <template
        v-slot:cell(ar_content)="value"
      >
        <div class="desTrim">
          {{ value.item.ar_content }}
        </div>
      </template>
      <template
        v-slot:cell(en_content)="value"
      >
        <div class="desTrim">
          {{ value.item.en_content }}
        </div>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'admin/testimonials',
      addType: 'Add Testimonial',
      addComponentName: 'add-testimonial',
      editComponent: 'edit-testimonial',
      viewContent: true,
      type: 'page',
      columns: [
        { key: 'id', sortable: true },
        { key: 'avatar', label: 'Image' },
        { key: 'ar_name', label: 'AR Name' },
        { key: 'en_name', label: 'EN Name' },
        { key: 'ar_position', label: 'AR Position' },
        { key: 'en_position', label: 'EN Position' },
        { key: 'ar_content', label: 'AR Content' },
        { key: 'en_content', label: 'EN Content' },
        { key: 'created_at', label: 'Created At' },
        { key: 'actions' },
      ],
    }
  },
}
</script>

<style>

</style>
